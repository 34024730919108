/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql, navigate } from 'gatsby';
import {
  isKoreanBrowser,
  hasKoreanSetting,
  extractPostNodes,
} from '../lib/helpers';
import PostPreviewList from '../components/post-preview-list';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import Layout from '../components/layout';
import NavBar from '../components/nav-bar';
import Footer from '../components/footer';
import { navBarHeightsInArray } from '../components/const';
import { useEffect } from 'react';

export const query = graphql`
  query {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      keywords
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          isKorean
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          description
          slug {
            current
          }
        }
      }
    }
  }
`;

const Main = props => {
  const { data, path, errors, pageContext } = props;
  const { lang } = pageContext;
  const { redirectedFromIndex } = props.location.state || {};
  useEffect(() => {
    if (path === '/' && (isKoreanBrowser() || hasKoreanSetting())) {
      navigate('/ko', {
        replace: true,
        state: {
          redirectedFromIndex: true,
        },
      });
    }
  }, [path]);
  useEffect(() => {
    if (redirectedFromIndex) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    }
  }, [redirectedFromIndex]);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const postNodes = extractPostNodes((data || {}).posts, lang);

  return (
    <Layout lang={lang}>
      <SEO
        title={site.title}
        description={null}
        keywords={site.keywords}
        path={path}
        lang={lang}
        image={postNodes[0].mainImage}
      />
      <NavBar lang={lang} koUrl="/ko" enUrl="/en" />
      <PostPreviewList
        lang={lang}
        nodes={postNodes}
        sx={{ mt: navBarHeightsInArray }}
      />
      <Footer
        sx={{ mt: [112, 160, 180] }}
        lang={lang}
        koUrl="/ko"
        enUrl="/en"
      />
    </Layout>
  );
};

export default Main;
